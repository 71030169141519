/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./unauthorized.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ionic/angular/ionic-angular.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "./unauthorized.component";
var styles_UnauthorizedComponent = [i0.styles];
var RenderType_UnauthorizedComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UnauthorizedComponent, data: {} });
export { RenderType_UnauthorizedComponent as RenderType_UnauthorizedComponent };
export function View_UnauthorizedComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "card-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "ion-card", [], null, null, null, i2.View_IonCard_0, i2.RenderType_IonCard)), i1.ɵdid(2, 49152, null, 0, i3.IonCard, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "ion-icon", [["name", "alarm-outline"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(4, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(5, 0, null, 0, 4, "ion-card-header", [], null, null, null, i2.View_IonCardHeader_0, i2.RenderType_IonCardHeader)), i1.ɵdid(6, 49152, null, 0, i3.IonCardHeader, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(7, 0, null, 0, 2, "ion-card-title", [], null, null, null, i2.View_IonCardTitle_0, i2.RenderType_IonCardTitle)), i1.ɵdid(8, 49152, null, 0, i3.IonCardTitle, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["Endere\u00E7o Expirado"])), (_l()(), i1.ɵeld(10, 0, null, 0, 2, "ion-card-content", [], null, null, null, i2.View_IonCardContent_0, i2.RenderType_IonCardContent)), i1.ɵdid(11, 49152, null, 0, i3.IonCardContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, [" Este endere\u00E7o encontra-se expirado. Caso deseje uma nova sess\u00E3o de teleperitagem contacte a companhia. "]))], function (_ck, _v) { var currVal_0 = "alarm-outline"; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_UnauthorizedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-unauthorized", [], null, null, null, View_UnauthorizedComponent_0, RenderType_UnauthorizedComponent)), i1.ɵdid(1, 114688, null, 0, i4.UnauthorizedComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UnauthorizedComponentNgFactory = i1.ɵccf("app-unauthorized", i4.UnauthorizedComponent, View_UnauthorizedComponent_Host_0, {}, {}, []);
export { UnauthorizedComponentNgFactory as UnauthorizedComponentNgFactory };
