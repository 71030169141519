import { Routes } from '@angular/router';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
const ɵ0 = () => import("./video-insured/video-insured.module.ngfactory").then(m => m.VideoInsuredModuleNgFactory);
const routes = [
    {
        path: 'sms/:token',
        loadChildren: ɵ0
    },
    {
        path: 'unauthorized',
        component: UnauthorizedComponent
    },
    {
        path: '',
        redirectTo: 'sms/:token',
        pathMatch: 'full'
    }
];
export class AppRoutingModule {
}
export { ɵ0 };
