// Angular
import { Injectable } from '@angular/core';

// Socket
import * as io from 'socket.io-client';

// Environment
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SocketIOService {
  private socket: SocketIOClient.Socket;

  constructor() {}

  getSocket = () => this.socket;

  setupSocketConnection = () => {
    this.socket = io(environment.SOCKET_ENDPOINT, { path: '/api/socket.io', transports: ['websocket'] });
  };
}
