// Angular
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';

// Rxjs
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

// Services
import { VideoInsuredService } from '../video-insured/services/video-insured.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class HttpInterceptorService implements HttpInterceptor {
  constructor(private router: Router, private videoInsuredService: VideoInsuredService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken = this.videoInsuredService.getAuthToken();

    let tokenRequest: HttpRequest<any>;

    if (!req.url.includes('googleapis')) {
      tokenRequest = req.clone({
        setHeaders: {
          Authorization: `Bearer ${authToken}` ? `Bearer ${authToken}` : `Bearer null`,
        },
      });
    } else {
      tokenRequest = req;
    }

    return next.handle(tokenRequest).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          // console.log('HttpResponse');
          // console.log(event);
        }
      }),
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          try {
            if (err.status === 410) {
              console.log(`%cUnauthorized`, `color: red`);
              this.router.navigateByUrl(`/unauthorized`);
            }

            if (err.status === 504 || err.status === 401 || (err.status === 400 && err.message === 'Invalid cookie value')) {
              // alert(err.error.message ? err.error.message : err.message);
            }
          } catch (e) {
            console.log(err);
          }
        }
        return of(err);
      })
    );
  }
}
