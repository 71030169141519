<div class="card-wrapper">
  <ion-card>
    <ion-icon name="alarm-outline"></ion-icon>
    <ion-card-header>
      <ion-card-title>Endereço Expirado</ion-card-title>
    </ion-card-header>
    <ion-card-content>
      Este endereço encontra-se expirado. Caso deseje uma nova sessão de teleperitagem contacte a companhia.
    </ion-card-content>
  </ion-card>
</div>